html,
body {
  padding: 0;
  height: auto !important;
  font-family: -apple-system, BlinkMacSystemFont, Segoe UI, Roboto, Oxygen,
  Ubuntu, Cantarell, Fira Sans, Droid Sans, Helvetica Neue, sans-serif;

  font-variant: tabular-nums;
  line-height: 1.5715;
  background-color: #fff;
  font-feature-settings: "tnum", "tnum";
}

a {
  color: inherit;
  text-decoration: none;
}

* {
  box-sizing: border-box;
}
